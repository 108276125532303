import React, { useState } from 'react';
import { motion } from 'framer-motion';

const images = [
  'images/gallery/1.jpg',
  'images/gallery/9.jpg',
  'images/gallery/3.jpg',
  'images/gallery/4.jpg',
  'images/gallery/5.jpg',
  'images/gallery/6.jpg'
];

export default function Gallery() {
    const [selectedImage, setSelectedImage] = useState(null);

  return (
    <section id="gallery" className="py-16 bg-gray-100">
      <div className="container px-4 sm:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">Our Property Gallery</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer"
              onClick={() => setSelectedImage(image)}
            >
              <img src={image} alt={`Property ${index + 1}`} className="w-full h-64 object-cover" />
            </motion.div>
          ))}
        </div>
      </div>
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={() => setSelectedImage(null)}
        >
          <div className="relative">
            <img src={selectedImage} alt="Zoomed property" className="max-w-full max-h-full" />
            <button
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={() => setSelectedImage(null)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </section>
  );
}