import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface HeaderProps {
  scrollToSection: (id: string) => void;
}

export default function Header({ scrollToSection }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = ['properties', 'gallery', 'about'];

  const handleMenuItemClick = (item: string) => {
    setIsMenuOpen(false);
    setTimeout(() => {
      scrollToSection(item);
    }, 100);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-white">
      <div className="container flex h-16 items-center justify-between px-4 sm:px-8">
        <a href="/" className="flex items-center space-x-2">
          <img src="images/HIP Land-2.JPG" alt="Binamandiri Logo" width={40} height={40} className="rounded-full" />
          <span className="text-xl font-bold text-orange-600">Binamandiri</span>
        </a>
        <nav className="hidden md:flex space-x-6">
          {menuItems.map((item) => (
            <button
              key={item}
              onClick={() => scrollToSection(item)}
              className="text-sm font-medium text-gray-700 hover:text-orange-600 capitalize"
            >
              {item}
            </button>
          ))}
        </nav>
        <button
          className="md:hidden w-10 h-10 flex items-center justify-center"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <div className="w-6 h-6 relative">
            <span
              className={`absolute h-0.5 w-6 bg-gray-600 transform transition-all duration-200 ease-in-out ${
                isMenuOpen ? 'rotate-45 translate-y-2.5' : ''
              }`}
            ></span>
            <span
              className={`absolute top-2.5 h-0.5 w-6 bg-gray-600 transform transition-all duration-200 ease-in-out ${
                isMenuOpen ? 'opacity-0' : ''
              }`}
            ></span>
            <span
              className={`absolute bottom-0 h-0.5 w-6 bg-gray-600 transform transition-all duration-200 ease-in-out ${
                isMenuOpen ? '-rotate-45 -translate-y-2.5' : ''
              }`}
            ></span>
          </div>
        </button>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="md:hidden bg-white border-t fixed w-full top-16 left-0 right-0 bottom-0 overflow-y-auto"
          >
            <nav className="flex flex-col space-y-4 p-4">
              {menuItems.map((item) => (
                <button
                  key={item}
                  onClick={() => handleMenuItemClick(item)}
                  className="text-sm font-medium text-gray-700 hover:text-orange-600 capitalize py-2"
                >
                  {item}
                </button>
              ))}
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}