import React from 'react';
import { motion } from 'framer-motion';

const properties = [
  {
    title: "Building Construction for Offices and Shophouses",
    description: "Commercial building and shophouse construction services tailored to your business needs",
    image: "images/properties/1.jpg",
    delay: 0,
  },
  {
    title: "Property Management",
    description: "Professional property management services to optimize the value of your property investment",
    image: "images/properties/2.jpg",
    delay: 0.2,
  },
  {
    title: "Office Space Rental",
    description: "Modern office space rental solutions in strategic locations",
    image: "images/properties/3.jpg",
    delay: 0.4,
  },
];

export default function Properties() {
  return (
    <section id="properties" className="py-16 bg-gradient-to-b from-gray-50 to-white">
      <div className="container px-4 sm:px-8 mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl md:text-4xl font-bold text-center mb-4"
        >
          Our Services
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-gray-600 text-center mb-12 max-w-2xl mx-auto"
        >
          Comprehensive commercial property solutions for your business needs
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {properties.map((property, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: property.delay }}
              className="group relative overflow-hidden rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <div className="aspect-w-16 aspect-h-9 overflow-hidden">
                <img
                  src={property.image}
                  alt={property.title}
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>

              <div className="p-6 flex flex-col h-full">
                <h3 className="text-xl font-semibold mb-3 group-hover:text-orange-600 transition-colors duration-300">
                  {property.title}
                </h3>
                <p className="text-gray-600 flex-grow">
                  {property.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}