import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import CallToAction from './components/CallToAction';
import Properties from './components/Properties';
import Footer from './components/Footer';
import Gallery from './components/Gallery';


export default function App() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="flex min-h-screen flex-col">
      <Header scrollToSection={scrollToSection} />

      <main className="flex-1">
        <Hero scrollToSection={scrollToSection} />
        <Properties />
        <Gallery />
        <AboutUs />
        <CallToAction />
      </main>

      <Footer scrollToSection={scrollToSection} />

      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
            className="fixed bottom-4 right-4 z-50"
          >
            <button 
              className="p-3 rounded-full bg-orange-500 hover:bg-orange-600 text-white shadow-lg transition duration-300"
              onClick={scrollToTop}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
              </svg>
              <span className="sr-only">Scroll to top</span>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}