import React from 'react';
import { motion } from 'framer-motion';

export default function CallToAction() {
  return (
    <section className="py-16 bg-orange-600 text-white">
      <div className="container px-4 sm:px-8 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-4">
              Ready to Find Your Dream Property?
            </h2>
            <p className="mb-8 text-lg">
              Let our experts guide you through the process of finding the perfect property that meets all your needs.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-6 py-3 bg-white text-orange-600 rounded-md hover:bg-gray-100 transition duration-300 font-semibold"
            >
              Contact Us Today
            </motion.button>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="w-full h-[300px] rounded-lg overflow-hidden shadow-lg"
          >
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d467.94661107632567!2d106.83699773617934!3d-6.240397013744943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc18321b606fee171!2sHIP%20LAND!5e0!3m2!1sen!2sid!4v1661224886624!5m2!1sen!2sid" 
              width="100%" 
              height="300" 
              style={{ border: 0 }} 
              allowFullScreen 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
              title="Bina Mandiri Location"
              className="rounded-lg"
            ></iframe>
          </motion.div>
        </div>
      </div>
    </section>
  );
}