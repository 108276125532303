import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface HeroProps {
  scrollToSection: (id: string) => void;
}

const slides = [
    {
      image: 'images/slide/4.jpg',
      title: 'Discover Your Dream Shophouse',
      description: 'Binamandiri offers premium shophouse solutions tailored to your business needs.',
    },
    {
      image: 'images/slide/5.jpg',
      title: 'Exclusive Shophouses Await',
      description: 'Experience the ease of doing business in our strategically located shophouses.',
    },
    {
      image: 'images/slide/3.jpg',
      title: 'The Ideal Shophouse for Your Business',
      description: 'Let us help you find the shophouse that aligns with your business vision and aspirations.',
    },
  ];

export default function Hero({ scrollToSection }: HeroProps) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <section className="relative h-[600px] overflow-hidden">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="relative h-[600px]">
            <img
              src={slide.image}
              alt={`Luxury Property ${index + 1}`}
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-black/50">
              <div className="container h-full flex flex-col justify-center px-4 sm:px-8">
                <motion.h1 
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                  className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4"
                >
                  {slide.title}
                </motion.h1>
                <motion.p 
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                  className="text-xl text-gray-200 mb-8 max-w-xl"
                >
                  {slide.description}
                </motion.p>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.6, duration: 0.5 }}
                >
                  <button 
                    className="px-6 py-3 bg-orange-600 text-white rounded-md hover:bg-orange-700 transition duration-300"
                    onClick={() => scrollToSection('properties')}
                  >
                    Explore Properties
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="absolute inset-0 pointer-events-none">
        <div className="bubble bubble-1"></div>
        <div className="bubble bubble-2"></div>
        <div className="bubble bubble-3"></div>
        <div className="bubble bubble-4"></div>
        <div className="bubble bubble-5"></div>
      </div>
    </section>
  );
}