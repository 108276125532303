import React, { useState } from 'react';
import { motion } from 'framer-motion';

const content = {
  id: {
    title: "BINA MANDIRI",
    paragraph1: "<span class='font-semibold'>BINAMANDIRI MAJUGEMILANG</span> adalah bagian dari perusahaan group Hero Intiputra yang bergerak dibidang manajemen property, land development dan office renting.",
    paragraph2: "Telah banyak properti yang dikembangkan dan dikelola oleh PT. BINAMANDIRI MAJUGEMILANG dan tersebar di beberapa wilayah seperti Giant Pondok Gede Bekasi, Giant kreo Ciledug Tangerang, Giant Taman Yasmine Bogor dan beberapa tempat yang lain.",
    paragraph3: "PT. BINAMANDIRI MAJUGEMILANG memiliki aset berupa tanah pekarangan di daerah kota Cilegon, Banten yang siap digunakan untuk pengembangan properti masa depan."
  },
  en: {
    title: "BINA MANDIRI",
    paragraph1: "<span class='font-semibold'>BINAMANDIRI MAJUGEMILANG</span> is part of the Hero Intiputra group of companies, specializing in property management, land development, and office renting.",
    paragraph2: "PT. BINAMANDIRI MAJUGEMILANG has developed and managed numerous properties across various regions, including Giant Pondok Gede Bekasi, Giant Kreo Ciledug Tangerang, Giant Taman Yasmine Bogor, and several other locations.",
    paragraph3: "PT. BINAMANDIRI MAJUGEMILANG owns land assets in the Cilegon city area, Banten, which are ready for future property development."
  }
};

export default function AboutUs() {
  const [language, setLanguage] = useState('id');

  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'id' ? 'en' : 'id');
  };

  return (
    <section id="about" className="py-16 bg-gray-50">
      <div className="container px-4 sm:px-8 mx-auto max-w-6xl">
        <div className="flex justify-end mb-4">
          <button
            onClick={toggleLanguage}
            className="flex items-center space-x-2 px-3 py-1 bg-white rounded-md shadow-sm hover:bg-gray-100 transition-colors duration-200"
          >
          
            <span className="text-sm font-medium">{language === 'id' ? 'EN' : 'ID'}</span>
          </button>
        </div>
        
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl md:text-5xl font-bold text-center mb-12 text-orange-500"
        >
          {content[language].title}
        </motion.h2>
        
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <motion.div 
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="lg:w-1/2"
          >
            <img 
              src="images/about.jpg" 
              alt="Bina Mandiri Office" 
              className="rounded-lg shadow-lg w-full h-auto object-cover"
            />
          </motion.div>
          
          <motion.div 
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="lg:w-1/2 space-y-4"
          >
            {['paragraph1', 'paragraph2', 'paragraph3'].map((para, index) => (
              <motion.p 
                key={para}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * (index + 1), duration: 0.5 }}
                className="text-base leading-relaxed"
                dangerouslySetInnerHTML={{ __html: content[language][para] }}
              />
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
}