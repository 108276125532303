import React from 'react';

interface FooterProps {
  scrollToSection: (id: string) => void;
}

export default function Footer({ scrollToSection }: FooterProps) {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container px-4 sm:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Binamandiri</h3>
            <p className="text-sm text-gray-400">
              Your trusted partner in finding the perfect property across Indonesia.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {['Properties', 'Gallery', 'About Us'].map((item) => (
                <li key={item}>
                  <button onClick={() => scrollToSection(item.toLowerCase().replace(' ', ''))} className="text-sm text-gray-400 hover:text-white">
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <address className="text-sm text-gray-400 not-italic">
              <p className="font-semibold">PT. Binamandiri Majugemilang</p>
              <p>Jl. Jend. Gatot Subroto No. 177 kav.64</p>
              <p>Jakarta</p>
              <p>Tel: <a href="tel:+62218317733" className="hover:text-white">(021) 831-7733</a></p>
              <p>Fax: (021) 831-7836</p>
              <p>Leasing & Marketing Manager: Feelycia</p>
              <p>Email: <a href="mailto:ria.feelycia@hip.co.id" className="hover:text-white">ria.feelycia@hip.co.id</a></p>
            </address>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-sm text-gray-400">
          © {new Date().getFullYear()} PT. Binamandiri Majugemilang. All rights reserved.
        </div>
      </div>
    </footer>
  );
}